// Define types for Clerk's unsafeMetadata for our app
export interface ClerkUserMetadata {
  birthdate?: string;
  accepted_terms_at?: string;
  tcpa_accepted_at?: string;
  account_completed?: boolean;
  analytics_uuid?: string;
  initial_anonymous_id?: string;
}

// Helper functions to safely get typed values from Clerk's metadata
export function getMetadataString(value: unknown): string | undefined {
  return typeof value === 'string' ? value : undefined;
}

export function getMetadataBoolean(value: unknown): boolean {
  return Boolean(value);
}

export function getUserMetadata(metadata: Record<string, unknown> | null | undefined): ClerkUserMetadata {
  if (!metadata) return {};

  return {
    birthdate: getMetadataString(metadata.birthdate),
    accepted_terms_at: getMetadataString(metadata.accepted_terms_at),
    tcpa_accepted_at: getMetadataString(metadata.tcpa_accepted_at),
    account_completed: getMetadataBoolean(metadata.account_completed),
    analytics_uuid: getMetadataString(metadata.analytics_uuid),
    initial_anonymous_id: getMetadataString(metadata.initial_anonymous_id),
  };
}
